import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helper/TextSerializer";

class ListContent extends React.Component {
  containerRef = React.createRef();
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { toggle: false, showTruncate: false };
  }

  handleClick(e) {
    e.preventDefault();
    this.setState(state => ({
      toggle: !state.toggle,
    }));
  }

  componentDidMount() {
    const container = this.containerRef?.current;
    if (container) {
      if (container.scrollHeight > container.clientHeight + 18) {
        this.setState({ showTruncate: true });
      }
    }
  }

  render() {
    const items =
      typeof this.props.items === "object"
        ? this.props.items
        : [this.props.items];
    return (
      <>
        {this.props.heading && (
          <h3 className="section-heading">{this.props.heading}</h3>
        )}
        {items?.textBlock?.[0] && (
          <>
            <div
              ref={this.containerRef}
              // className={`bio-list${this.state.toggle ? "" : " -truncate"}`}
              className={`bio-list`}
            >
              <BlockContent
                blocks={items.textBlock}
                className="bio-list-inner"
                renderContainerOnSingleChild={true}
                serializers={{
                  types: {
                    block: serializers.types.block,
                    undefined: serializers.undefined,
                    image: serializers.image,
                    youtube: serializers.types.youtube,
                  },
                  marks: serializers.marks,
                }}
              />
            </div>
            {/* {!this.state.toggle && this.state.showTruncate && (
              <a
                href="#"
                className="see-more"
                onClick={e => this.handleClick(e)}
              >
                <span className="see-more-span">+ See more</span>
              </a>
            )}
            {this.state.toggle && this.state.showTruncate && (
              <a
                href="#"
                className="see-more"
                onClick={e => this.handleClick(e)}
              >
                <span className="see-more-span">- See less</span>
              </a>
            )} */}
          </>
        )}
      </>
    );
  }
}

export default ListContent;
