import React from "react";

class EmailLink extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.mouseFunction = this.mouseFunction.bind(this);
    this.removeMouseFunction = this.removeMouseFunction.bind(this);
  }

  removeMouseFunction() {
    document.body.onmousemove = "";
    const cursorText = document.getElementById("cursorText");
    if (cursorText) {
      cursorText.parentElement.removeChild(cursorText);
    }
  }

  mouseFunction(event) {
    if (!document.getElementById("cursorText")) {
      document.body.onmousemove = moveCursor;
      const curTxt = document.createElement("div");
      curTxt.id = "cursorText";
      const para = document.createElement("P");
      const t = document.createTextNode("copied to clipboard");
      para.appendChild(t);
      para.className = "caption";
      curTxt.appendChild(para);
      document.body.appendChild(curTxt);
      const curTxtLen = [curTxt.offsetWidth, curTxt.offsetHeight];
      const bound = curTxt.getBoundingClientRect();
      curTxt.style.left = event.pageX - curTxtLen[0] + bound.width + "px";
      curTxt.style.top = event.pageY - curTxtLen[1] + "px";
      function moveCursor(e) {
        if (!e) {
          e = window.event;
        }
        curTxt.style.left = e.pageX - curTxtLen[0] + bound.width + "px";
        curTxt.style.top = e.pageY - curTxtLen[1] + "px";
      }
    }
  }

  handleClick(event, text) {
    event.preventDefault();
    if (navigator.clipboard !== undefined) {
      navigator.clipboard.writeText(text);
    } else if (window.clipboardData) {
      // Internet Explorer
      window.clipboardData.setData("Text", text);
    }
    this.mouseFunction(event);
    setTimeout(() => {
      let x = document.getElementById("cursorText");
      if (x) {
        x.classList.add("-show");
      }
    }, 200);
    setTimeout(() => {
      let x = document.getElementById("cursorText");
      if (x) {
        x.classList.remove("-show");
      }
    }, 1600);
    setTimeout(() => {
      this.removeMouseFunction();
    }, 2000);
  }

  render() {
    return (
      <a
        onClick={e => this.handleClick(e, this.props.content)}
        href="#"
        className="contact-clipboard"
      >
        {this.props.content}
      </a>
    );
  }
}

export default EmailLink;
