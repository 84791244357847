import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import Layout from "../components/layout";
import ListContent from "../components/list-content";
import EmailLink from "../components/email-link";
import SEO from "../components/seo";
import PersonTile from "../components/personTile";
import formatObjectPosition from "../helper/Image";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helper/TextSerializer";
import Fader from "../helper/Fader";
import RepresentativeWork from "../components/expertise-list";

export const query = graphql`
  query PersonTemplateQuery($id: String!) {
    page: allSanityPage(filter: { slug: { current: { eq: "people" } } }) {
      nodes {
        backgroundImage {
          asset {
            fixed(width: 1600, height: 900) {
              ...GatsbySanityImageFixed_withWebp_noBase64
            }
          }
        }
      }
    }
    person: sanityPerson(id: { eq: $id }) {
      id
      fullName
      email
      cv {
        asset {
          url
          size
          extension
        }
      }
      bioLists {
        listHeading
        _rawListItems(resolveReferences: { maxDepth: 8 })
      }
      pageSEO {
        metaDescription
        metaTitle
        metaImage {
          asset {
            url
          }
        }
      }
      practiceAreas {
        title
        slug {
          current
        }
      }
      role
      abbreviations
      _rawIntroduction(resolveReferences: { maxDepth: 8 })
      image {
        hotspot {
          x
          y
        }
        alt
        asset {
          fluid(maxWidth: 1000, maxHeight: 1000) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
    }
    people: allSanityPerson(
      filter: { id: { ne: $id } }
      limit: 3
      sort: { order: ASC, fields: order }
    ) {
      nodes {
        id
        order
        fullName
        role
        abbreviations
        practiceAreas {
          title
        }
        slug {
          current
        }
        image {
          alt
          hotspot {
            x
            y
          }
          asset {
            fluid(maxHeight: 300, maxWidth: 300) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const PersonTemplate = ({ data }) => {
  const { page, person, people } = data;

  return (
    <Layout
      className="page-person-container"
      backgroundImage={page.nodes[0].backgroundImage?.asset?.fixed}
    >
      <SEO
        title={(person.pageSEO && person.pageSEO.metaTitle) || person.fullName}
        description={person.pageSEO && person.pageSEO.metaDescription}
        image={person.pageSEO && person.pageSEO.metaImage}
      />
      <Fader>
        <section className="bio-intro">
          <div className="bio-hero">
            <Img
              fluid={person.image.asset.fluid}
              alt={person.image.alt || person.fullName}
              aspectRatio={1}
              objectFit={"contain"}
              objectPosition={formatObjectPosition(person.image.hotspot)}
            />
            <div className="bio-hero-content">
              <h1 className="page-heading">{person.fullName}</h1>

              {person.role && (
                <h2 className="bio-role">
                  {person.abbreviations && (
                    <span className="caption">{`${person.abbreviations} `}</span>
                  )}
                  {person.role}
                </h2>
              )}
              <h4 className="contact-cta">
                Contact {person.fullName.split(" ", 1)}
              </h4>
              <span>
                <EmailLink content={person.email} />
              </span>
            </div>
          </div>
          {(person.introduction?.textBlock[0] || person.practiceAreas?.[0]) && (
            <div className="bio-intro-text-container">
              {person._rawIntroduction?.textBlock?.[0] && (
                <div className="person-intro-text">
                  <BlockContent
                    blocks={person._rawIntroduction.textBlock}
                    serializers={serializers}
                  />
                </div>
              )}
            </div>
          )}
        </section>
      </Fader>
      <Fader>
        {(person.bioLists?.[0] || person.cv || person.practiceAreas?.[0]) && (
          <section className="page-body">
            <div className="page-body-inner">
              {person.practiceAreas?.[0] && (
                <RepresentativeWork
                  title = "Practice Areas"
                  representativeWork={person.practiceAreas}
                  device="desktop"
                />
              )}
              <div>
                {person.bioLists.map((list, i) => {
                  return (
                    <ListContent
                      key={i}
                      heading={list?.listHeading}
                      items={list?._rawListItems}
                    />
                  );
                })}
                {person.cv && (
                  <div className="cv-container">
                    <a
                      className="cv-link"
                      target="_blank"
                      rel="noreferrer"
                      href={person.cv.asset.url}
                    >
                      Download CV
                    </a>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
      </Fader>

      <Fader>
        <section className="firm-people">
          <div className="people-cta-container">
            <h2 className="people-heading">Our people</h2>
            <Link className="people-more-cta -desktop" to="/people">
              See all
            </Link>
          </div>
          <div className="firm-people-container">
            {people.nodes.map((person, i) => {
              return <PersonTile key={i} person={person} aspectRatio={1} />;
            })}
          </div>
          <Link className="people-more-cta -tablet" to="/people">
            See all
          </Link>
        </section>
      </Fader>
    </Layout>
  );
};

export default PersonTemplate;
